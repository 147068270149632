import { render, staticRenderFns } from "./Cookie_consent.vue?vue&type=template&id=000b1413&scoped=true&"
import script from "./Cookie_consent.vue?vue&type=script&lang=js&"
export * from "./Cookie_consent.vue?vue&type=script&lang=js&"
import style0 from "./Cookie_consent.vue?vue&type=style&index=0&id=000b1413&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "000b1413",
  null
  
)

export default component.exports