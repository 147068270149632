<template>
    <div 
    class="cookieNotice">
    This site uses cookies for essential functions, personalization, and analytics. Read our <a href="https://sainaplay.com/privacy-policy" class="privacy" >Privacy Policy</a>  for more information.
    <a @click="updateCookies(true)" class="acceptance">Accept</a>
    <img @click="updateCookies(false)" class="decline" src="@/assets/icons/exit_consent.svg" alt="">
    </div>
</template>
    
    <script>
    import { mapMutations } from 'vuex';
    export default {
        data() {
            return {
                notDenied: true
            }
        },
        methods: {
            ...mapMutations(['commitCookiePermission']),
            updateCookies(val) {
                this.notDenied = val;
                this.commitCookiePermission(val);
                localStorage.setItem('cookies_permission', `${val}`);
            }
        }
    }
    </script>
    
    <style lang="scss" scoped>
    .cookieNotice {
     position: fixed; 
     bottom: 0px; 
     left: 0px; 
     right: 0px; 
     background: rgb(0, 0, 0); 
     color: rgb(255, 255, 255); 
     z-index: 999; 
     padding: 10px 5px; 
     text-align: center; 
     font-size: 0.75rem; 
     line-height: 28px;
    }
    .decline {
     vertical-align: middle;
     cursor: pointer;
     margin-top: -0.2rem;
    }
    .acceptance {
     background: #df2228; 
     color: rgb(255, 255, 255); 
     text-decoration: none; 
     display: inline-block; 
     padding: 0px 15px; 
     margin: 0px 0px 0px 10px;
     cursor: pointer;
     border-radius: 0.1rem;
     font-size: 0.75rem;
    }
    @media only screen and (max-width: 600px) {
        .cookieNotice {
            font-size: 0.85rem;
        }
        .privacy {
            font-size: 0.85rem;
        }
        .decline {
            vertical-align: middle;
            cursor: pointer;
            margin-top: -0.2rem;
    }
    }
    </style>
    